exports.components = {
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-basic-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/basic.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-basic-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-extended-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/extended.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-extended-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-index-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/index.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-index-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-media-jsx": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/pages/dev/media.jsx" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-components-src-pages-dev-media-jsx" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/pages/storyblok-preview.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/templates/StoryblokDefaultTemplate.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js" */),
  "component---plugins-news-overview-source-plugin-news-overview-page-template-js": () => import("./../../../plugins/news-overview-source-plugin/newsOverviewPageTemplate.js" /* webpackChunkName: "component---plugins-news-overview-source-plugin-news-overview-page-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

